
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';

const gclient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: '/gql/'
    }),
    connectToDevTools: true,
});

const StandClient = {
    getFrontConfigure: () => new Promise((resolve, reject) => {
        let query = gql`query{getFrontConfigure{title, engine, headerBack, logoIcon, navLinks {title,link,sub {title,link} }}, rootCategorys { id,name,categorySet {id,name} } }`
        gclient.query({query:query}).then(result=>{
            localStorage.setItem('frontConfigure', JSON.stringify(result));
            resolve({result});
        }).catch(error=>{
            reject({error})
        });
    }),
    getFrontConfigureLocal: () => {
        return JSON.parse(localStorage.getItem('frontConfigure'));
    },
    getProducts: () => new Promise((resolve, reject) => {
        let query = gql`query { landrickProducts { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock, } } `;
        gclient.query({query:query}).then(result=>{
            resolve(result.data.landrickProducts);
        }).catch(error=> {
            reject(error);
        });
    }),
    getProductsInCategory: (category) => new Promise((resolve, reject) => {
        let query = gql('query { landrickProducts(categoryId:'+category+') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock, }, category(id:'+category+'){ id, name, } } ');
        gclient.query({query:query}).then(result=>{
            resolve(result.data);
        }).catch(error=> {
            reject(error);
        });
    }),
    getProduct: (id) => new Promise((resolve, reject) => {
        let query = gql('query { landrickProduct(id:'+id+') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock,description,prices { attributeValue { id,value,type {name,type}},price,oldPrice} }, photos(productId:'+id+') {file}, landrickRelatedProducts(id:'+id+') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock, } } ');
        gclient.query({query:query}).then(result=>{
            resolve(result.data);
        }).catch(error=> {
            reject(error);
        });
    }),
    getRelatedProducts: (id) => new Promise((resolve, reject) => {
        let query = gql('query { landrickRelatedProducts(id:'+id+') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock, } }');
        gclient.query({query:query}).then(result=>{
            resolve(result.data.landrickRelatedProducts);
        }).catch(error=> {
            reject(error);
        });
    }),
    sendOrder: (fields, paymentType, cart) => new Promise((resolve, reject) => {
        var newCart = [];
        Object.entries(JSON.parse(cart)).map(([key,value])=>{
            newCart.push({
                product: value.product.id,
                attributeValue: value.attributeValueId,
                quantity: value.quantity,
                selectedAttributes: value.selectedAttributes,
            })
        });
        if (newCart.length == 0) {
            reject('Ваша корзина пуста');
        }
        var address = fields['address'];
        if (address === undefined || address === '') {
            reject('Поле адрес не может быть пустым')
        }
        var house = fields['house'];
        var apartment = fields['apartment'];
        var phone = fields['phone'];
        var comment = fields['comment'];
        var email = fields['email'];
        var firstName = fields['firstName'];
        var lastName = fields['lastName'];

        let mutation = gql('mutation {sendOrder('+
            'cart:"'+JSON.stringify(newCart).replaceAll('"','\\"')+'",'+
            'address:"'+address.replaceAll('"','\\"')+'",'+
            'house:"'+house.replaceAll('"','\\"')+'",'+
            'apartment:"'+apartment.replaceAll('"','\\"')+'",'+
            'phone:"'+phone+'",'+
            'comment:"'+comment.replaceAll('"','\\"')+'",'+
            'email:"'+email+'",'+
            'firstName:"'+firstName.replaceAll('"','\\"')+'",'+
            'lastName:"'+lastName.replaceAll('"','\\"')+'",'+
            'paymentType:"'+paymentType+'"'+
            ') {order {id,key}}}'
        );
        gclient.mutate({mutation:mutation}).then(result=>{
            localStorage.setItem('cart', '{}')
            resolve(result.data);
        }).catch(error=> {
            reject(error);
        });
    }),
    getOrder: (id) => new Promise((resolve, reject) => {
        var prevOrders = null;
        try {
            prevOrders = JSON.parse(localStorage.getItem('prevOrders'))
        } catch {
            reject(`Ваш заказ #${id} не найден!`);
        }
        var orderKey = null;
        prevOrders.map((val)=>{
            console.log(val);
            if (val.id == id) {
                orderKey = val.key;
            }
        });
        if (orderKey === null) {
            reject(`Ваш заказ #${id} не найден!`);
        }
        let query = gql('query {'+
            'landrickOrder(id:'+id+',key:"'+orderKey+'") {'+
            'order { id,paymentType,comment }'+
            ' customer {'+
            'firstName,lastName,phone,email}'+
            'delivery {address,house,apartment,phone,}'+
            'products {'+
            '    product { name }'+
            '    quantity,unitPrice,withDiscount'+
            '    discount {value,percent}'+
            '    attributeValue {value} } } }');
        gclient.query({query:query}).then(result=>{
            resolve(result.data.landrickOrder);
        }).catch(error=> {
            reject(error);
        });
    }),
    getPage: (id) => new Promise((resolve, reject) => {
        let query = gql('query {page(id:'+id+') { id, name, content, parent { id, name, content } } }');
        gclient.query({query:query}).then(result=>{
            resolve(result.data.page);
        }).catch(error=> {
            reject(error);
        });
    }),

};

export default StandClient;
