import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

import StandClient from './client/index'

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/colors/default.css";

// Include Routes
import routes from "./routes";

function withLayout(WrappedComponent, hasDarkTopBar) {
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent params={this.props.match.params}></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {

  constructor() {
    super();
    this.state = {
      frontConfigure: null,
    };
  }

  componentDidMount() {
    StandClient.getFrontConfigure().then(result=>{
      this.setState({ frontConfigure: result.result.data.getFrontConfigure })
    })
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    if (this.state.frontConfigure == null) {
      return (<this.Loader />);
    }
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              {routes.map((route, idx) => {
                return (<Route
                    path={route.path}
                    exact={route.exact}
                    component={withLayout(route.component,false)}
                    key={idx}
                  />);
              })}
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
